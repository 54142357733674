export function About() {
  return (
    <>
      <h1>About share.limoengroen.nl</h1>
      <p>
        This webapplication allows one to share short snippets of information,
        such as account information, passwords or decryption keys with a
        single-use, time-limited URL.
      </p>
      <p>
        Only users possessing the URL will be able to decrypt the stored
        information.
      </p>
      <h2>Host your own?</h2>
      <p>
        If you'd like to use a custom branded version of this application,
        please contact{' '}
        <a href="mailto:hallo@limoengroen.nl">hallo@limoengroen.nl</a> to
        discuss possibilities.
      </p>
    </>
  );
}
