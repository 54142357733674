import { useRef, useState } from 'react';

const autofocus = (element) => element?.focus();

export function SecretSubmissionForm() {
  const [secretValue, setSecretValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState('');

  const secretLinkRef = useRef();

  // TODO: Get max from config.
  const remaining = 2048 - secretValue.length;

  function handleOnChange(e) {
    setSecretValue(e.target.value);
  }

  function reset() {
    setData('');
    setIsLoading(false);
    setSecretValue('');
  }

  function handleCopy(e) {
    e.preventDefault();
    navigator.clipboard.writeText(secretLinkRef.current.value);
  }

  async function sendMessage(e) {
    e.preventDefault();

    setIsLoading(true);
    const response = await fetch(`${process.env.API_ENDPOINT}/Secret`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        value: secretValue,
      }),
    });
    const json = await response.json();
    setData(json);
    setIsLoading(false);
  }

  let url;
  if (data) {
    url = new URL(
      `${window.location.toString()}get/${data.id}/${encodeURIComponent(
        data.key,
      )}/${encodeURIComponent(data.signature)}`,
    );
  }

  return (
    <>
      <h1>Submit a secret</h1>
      <form action={''}>
        <div className="input">
          <label htmlFor={'secret-input'}>
            {!data ? (
              <>Enter the information to share:</>
            ) : (
              <>The information that will be shared:</>
            )}
          </label>
          <textarea
            id="secret-input"
            value={secretValue}
            rows={20}
            onChange={handleOnChange}
            disabled={!!data}
            ref={autofocus}
          />
          {!data && <p>Remaining characters allowed: {remaining}</p>}
        </div>
        {data && (
          <div className="result">
            <label htmlFor={'secret-result'}>
              The link to access the secret:
            </label>
            <textarea
              id="secret-result"
              ref={secretLinkRef}
              value={url.toString()}
              readOnly={true}
              rows={5}
            />
            <div className="actions">
              <button onClick={handleCopy} ref={autofocus}>
                Copy link to clipboard
              </button>
            </div>
          </div>
        )}
        <div className="actions">
          {data ? (
            <button onClick={reset}>Again</button>
          ) : (
            <button
              className={'btn'}
              onClick={sendMessage}
              disabled={
                data || isLoading || secretValue === '' || remaining < 0
              }
            >
              Send
            </button>
          )}
        </div>
      </form>
    </>
  );
}
