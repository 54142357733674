import { useEffect, useState } from 'react';

/**
 * Fetches data from an endpoint.
 *
 * Queries are passed as paths to the configured Spinque API endpoint from
 * context.
 *
 * @param {string} url
 * @param {string} method
 * @param {Object[]} [initialData]
 *   Initial state of the data object. When omitted defaults to an
 *   empty object.
 *
 * @returns {{isLoading: boolean, isError: boolean, data: Object}}
 *   Object holding loading and error state and data, an response data
 *   from state.
 */
const useApiData = (url, initialData) => {
  const [data, setData] = useState(initialData ?? {});
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    let isActive = true;
    const fetchData = async () => {
      isActive && setIsLoading(true);
      isActive && setIsError(false);
      try {
        const response = await fetchResponseJson(url, { method: 'GET' });
        isActive && setData(response);
      } catch (error) {
        isActive && setData(initialData);
        isActive && setIsError(true);
      }
      isActive && setIsLoading(false);
    };
    // Deliberately ignore the promise. Its setData side-effect is the goal.
    fetchData();
    // Update flag to prevent async actions from settings state on an unmounted
    // component and delayed requests from overwriting later requests.
    return () => {
      isActive = false;
    };
  }, [url]);
  return { data, isLoading, isError };
};

export default useApiData;

/**
 * Fetches json from a url.
 *
 * Helper for useApiData.
 *
 * @param {string} uri
 *   Uri to fetch.
 *
 * @param {object} options
 *   Fetch options.
 *
 * @returns {Promise<any>}
 */
async function fetchResponseJson(uri, options) {
  const result = await fetch(uri, options);
  return await result.json();
}
