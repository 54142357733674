import 'src/App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Home } from 'src/routes/Home.js';
import { NotFound } from 'src/routes/NotFound.js';
import { GetSecret } from 'src/routes/GetSecret.js';
import { About } from 'src/routes/About.js';
import { Page } from 'src/components/Page.js';

export function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Page />}>
          <Route path={'/'} exact={true} element={<Home />} />
          <Route
            path={'/get/:id/:key/:signature'}
            exact={true}
            element={<GetSecret />}
          />
          <Route path={'/about'} exact={true} element={<About />} />
          <Route path={'*'} element={<NotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
