import { Outlet } from 'react-router-dom';

export function Page() {
  return (
    <>
      <header className="page--header">
        <div className="layout-container">
          <img
            src="https://www.limoengroen.nl/themes/lg/logo.svg"
            alt="Logo LimoenGroen"
            width="236"
            height="47"
          />
        </div>
      </header>
      <main id="main-content" className="page--main">
        <div className="layout-container">
          <Outlet />
        </div>
      </main>
    </>
  );
}
