import { useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import useApiData from 'src/hooks/useApiData.js';

const autofocus = (element) => element?.focus();

export function GetSecret() {
  const textAreaRef = useRef();
  const [secret, setSecret] = useState('');
  const [consumeIsLoading, setConsumeIsLoading] = useState(false);

  let { id, key, signature } = useParams();

  id = parseInt(id);

  // Note that useParams() delivers urldecoded parameters.
  const secretParams = `id=${encodeURIComponent(id)}&key=${encodeURIComponent(
    key,
  )}&signature=${encodeURIComponent(signature)}`;

  const { isLoading, isError, data } = useApiData(
    `${process.env.API_ENDPOINT}/Secret/Exists?${secretParams}`,
  );

  function handleCopy(e) {
    e.preventDefault();
    navigator.clipboard.writeText(textAreaRef.current.value);
  }

  async function consumeSecret(e) {
    e.preventDefault();
    setConsumeIsLoading(true);

    const uri = `${process.env.API_ENDPOINT}/Secret/Consume?${secretParams}`;

    const response = await fetch(uri, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
    });
    const json = await response.json();
    setSecret(json);
    setConsumeIsLoading(false);
  }

  if (!id || !key || !signature) {
    return <p>The provided URL is not valid.</p>;
  }

  if (isLoading) {
    return (
      <>
        <h1>Retrieve secret</h1>
        <p>Verifying...</p>
      </>
    );
  } else if (data && data.exists === false) {
    return (
      <>
        <h1>Retrieve secret</h1>
        <p>Unable to retrieve the secret. Possible causes:</p>
        <ul>
          <li>The provided URL is not correct.</li>
          <li>The provided URL has expired.</li>
          <li>The provided URL has been used before.</li>
        </ul>
      </>
    );
  }

  return (
    <>
      <h1>Retrieve secret</h1>
      <form action={''}>
        <p>
          You are about to get the secret that was shared with you. Make sure to
          copy the message if necessary; the secret cannot be retrieved again.
        </p>
        {!secret && (
          <div className={'actions'}>
            <button onClick={consumeSecret} ref={autofocus}>
              Retrieve the secret
            </button>
          </div>
        )}
        {secret?.value && (
          <>
            <div className="result">
              <label htmlFor="secret-output">Decrypted content:</label>
              <textarea
                id="secret-output"
                ref={textAreaRef}
                value={secret.value}
                rows={20}
                readOnly
              />
              <div className={'actions'}>
                <button onClick={handleCopy} ref={autofocus}>
                  Copy decrypted content to clipboard
                </button>
              </div>
            </div>
          </>
        )}
      </form>
    </>
  );
}
